import React from 'react'
import { useCalculatedAirQuality } from '../hooks/useCalculatedAirQuality'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { ColoredQualityLabel } from '../common-code-dashboards/components/ColoredQualityLabel'
import { getLevelMessage } from '../common-code-dashboards/utils/levelUtils'
import { useDeviceSettingsInfo } from '../hooks/useDeviceSettingsInfo'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'
import { useCaoDeviceData } from '../hooks/useCaoDeviceData'

export function InsideCircle(): JSX.Element {
  const { airQuality } = useCalculatedAirQuality()
  const { data: deviceSettingsInfo } = useDeviceSettingsInfo()
  const { data: caoDeviceData } = useCaoDeviceData()
  const { t } = useTranslation()
  const { fontSizeTranslation } = useFontSizeTranslation()

  const name = deviceSettingsInfo?.deviceSettings?.nameInfo?.name || caoDeviceData?.name

  return (
    <>
      {airQuality !== undefined && (
        <BoxWithPosition boxWidth={400} positionLeft={DEFAULT_WIDTH / 2} positionTop={503} center>
          <ColoredQualityLabel airQualityPercentage={airQuality} fontSize='64px' />
        </BoxWithPosition>
      )}
      <BoxWithPosition boxWidth={600} positionLeft={DEFAULT_WIDTH / 2} positionTop={599} center>
        <Typography
          align='center'
          sx={{
            fontSize: fontSizeTranslation('64px'),
          }}
        >
          {t('Indoor_Air_Quality Test')}
        </Typography>
      </BoxWithPosition>

      {airQuality !== undefined && (
        <BoxWithPosition boxWidth={1160} positionLeft={DEFAULT_WIDTH / 2} positionTop={722} center>
          <Typography
            align='center'
            sx={{
              fontSize: fontSizeTranslation('156px'),
              fontWeight: 'bold',
              lineHeight: 1,
            }}
          >
            {getLevelMessage(airQuality, t)}
          </Typography>
        </BoxWithPosition>
      )}

      {name && (
        <BoxWithPosition boxWidth={800} positionLeft={DEFAULT_WIDTH / 2} positionTop={1099} center>
          <Typography
            align='center'
            sx={{
              fontSize: fontSizeTranslation('60px'),
              fontWeight: 'bold',
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </BoxWithPosition>
      )}
    </>
  )
}
